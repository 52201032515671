import ModalManager from './ModalManager';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { bindActionCreators, Dispatch } from 'redux';
import { closeModal } from '../../../../state/session/session.actions';
import { translate } from 'yoshi-flow-editor-runtime';

function mapStateToProps(state: AppState) {
  return {
    modals: state.session.modals,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      closeModal,
    },
    dispatch,
  );
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(ModalManager));
