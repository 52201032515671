import { parsePath, History, LocationDescriptor, LocationDescriptorObject, LocationState } from 'history';

type CreateHistory<O, H> = (options?: O) => History & H;

function preserveQueryParameters(history: History, location: LocationDescriptorObject): LocationDescriptorObject {
  const currentQuery = history.location.search;

  if (currentQuery) {
    location.search = currentQuery;
  }

  return location;
}

function createLocationDescriptorObject(location: LocationDescriptor, state?: LocationState): LocationDescriptorObject {
  return typeof location === 'string' ? { ...parsePath(location), state } : location;
}

export function createPreserveQueryHistory<O, H>(createHistory: CreateHistory<O, H>): CreateHistory<O, H> {
  return (options?: O) => {
    const history = createHistory(options);
    const oldPush = history.push,
      oldReplace = history.replace;
    history.push = (path: LocationDescriptor, state?: LocationState) => {
      oldPush.apply(history, [preserveQueryParameters(history, createLocationDescriptorObject(path, state))]);
    };
    history.replace = (path: LocationDescriptor, state?: LocationState) => {
      oldReplace.apply(history, [preserveQueryParameters(history, createLocationDescriptorObject(path, state))]);
    };

    return history;
  };
}
