import DispatchSummary from './DispatchSummary';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { deliveryAreaSelectorByDispatchAddress } from '../../../../state/selectors/deliveryAreaSelector';

function mapStateToProps(state: AppState) {
  const { restaurant } = state.session;
  const dispatchType = state.checkout.dispatch.type;
  const idealDeliveryArea = deliveryAreaSelectorByDispatchAddress(state);

  return {
    dispatchType,
    dispatchTime: state.checkout.dispatch.time,
    address: state.checkout.dispatch.type === 'delivery' ? state.checkout.dispatch.address : undefined,
    timezone: restaurant.timezone,
    deliveryInfos: restaurant.deliveryInfos,
    idealDeliveryArea: idealDeliveryArea?.dispatchInfo,
    disabled:
      state.session.restaurant.address.formatted === '' || state.session.restaurant.address.formatted === '[noskip]',
  };
}

export default connect(mapStateToProps)(DispatchSummary);
