import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import styles from './AddressInformation.scss';
import { SetDispatchTimePayload, SetDispatchTypePayload } from '../../../../state/checkout/checkout.actions.types';
import dataHooks from '../../data-hooks';
import DispatchTypeSelector from '../DispatchTypeSelector';
import DispatchTimeSelector from '../DispatchTimeSelector';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { ReactComponent as Error } from 'wix-ui-tpa/dist/statics/assets/icons/Error.svg';
import AddressInformationSummary from './AddressInformationSummary';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import RestaurantTakeoutDetails from './RestaurantTakeoutDetails';
import AddressInformationDelivery from './AddressInformationDelivery';
import Text from '../../core-components/Text';
import { Trans, translate, useBi, useExperiments } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import {
  SetAddressInputErrorPayload,
  SetErrorVisibilityPayload,
  ToggleAllErrorsPayload,
} from '../../../../state/addressInformationForm/addressForm.actions.types';
import Button from '../Button';
import {
  Address,
  DispatchInfo,
  DispatchType,
  DisplayableOrderItem,
  getCachedTranslationFunction,
  Restaurant,
  validateAddress,
  ValidateAddressReason,
  validateTakeout,
} from '@wix/restaurants-client-logic';
import { DeliveryFormField } from '../../../../state/addressInformationForm/addressForm.reducer';
import { getScrollOptions } from '../CheckoutFlow/CheckoutFlow';
import { getErrorKey } from './AddressInformation.helper';
import { BusinessNotificationDetails } from '../../../../state/selectors/businessNotificationSelector';

export interface AddressInformationProps {
  restaurant: Restaurant;
  dispatchTime: number;
  totalOrderPrice: number;
  dispatchType: DispatchType;
  done?: boolean;
  collapsed?: boolean;
  forceErrorVisibility?: boolean;
  index?: string;
  onSubmit: () => void;
  onEdit: () => void;
  setDispatchType: (dispatchType: SetDispatchTypePayload) => void;
  setDispatchTime: (payload: SetDispatchTimePayload) => void;
  address: Address;
  supportedDispatchTypes: Set<DispatchType>;
  formattedAddressWithComment: string;
  fieldsErrors: {
    addressInput: boolean;
    apt: boolean;
    timingOption: boolean;
  };
  toggleAllErrors: (payload: ToggleAllErrorsPayload) => void;
  setDeliveryAddressFromForm: () => void;
  t: TranslationFunction;
  isMobile: boolean;
  selectedAddressOption?: Address;
  setAddressInputError: (payload: SetAddressInputErrorPayload) => void;
  setFieldError: (payload: SetErrorVisibilityPayload) => void;
  setErrorVisibility: (payload: SetErrorVisibilityPayload) => void;
  addressInputError?: ValidateAddressReason;
  idealDeliveryArea?: DispatchInfo;
  errorOrderItem?: DisplayableOrderItem;
  saveStateToSessionStorage: () => void;
  timezone: string;
  errorsVisibility: Record<DeliveryFormField, boolean>;
  deliveryInfos: DispatchInfo[];
  describedby?: string;
  businessNotification?: BusinessNotificationDetails;
}

const AddressInformation: React.FC<AddressInformationProps> = ({
  setDispatchType,
  dispatchType,
  index = '0',
  done,
  collapsed,
  onEdit,
  address,
  onSubmit,
  restaurant,
  totalOrderPrice,
  dispatchTime,
  supportedDispatchTypes,
  setDispatchTime,
  formattedAddressWithComment,
  toggleAllErrors,
  setDeliveryAddressFromForm,
  t,
  isMobile,
  selectedAddressOption,
  setAddressInputError,
  setFieldError,
  addressInputError,
  fieldsErrors,
  idealDeliveryArea,
  errorOrderItem,
  saveStateToSessionStorage,
  setErrorVisibility,
  timezone,
  errorsVisibility,
  deliveryInfos,
  describedby,
  businessNotification,
}) => {
  const experiments = useExperiments();
  const location = useLocation();

  useEffect(() => {
    toggleAllErrors({ value: false });
  }, [location, toggleAllErrors]);

  const handleDispatchTimeSelectorChange = useCallback(
    ({ timingOption, selectedDateTime }) => {
      if (timingOption === 'asap') {
        setDispatchTime({ timestamp: undefined });
      } else {
        setDispatchTime({ timestamp: selectedDateTime });
      }
      setFieldError({ error: 'timingOption', value: false });
    },
    [setDispatchTime, setFieldError],
  );

  const [isTakeoutUnavailable, setIsTakeoutUnavailable] = useState(false);

  const timeError = fieldsErrors.timingOption && (isTakeoutUnavailable || addressInputError?.type === 'unavailable');

  useEffect(() => {
    if (errorsVisibility.addressInput || errorsVisibility.apt || errorsVisibility.timingOption) {
      scroller.scrollTo('address-input', getScrollOptions());
    }
  }, [errorsVisibility]);

  const biLogger = useBi();

  const setFieldErrorAndBi = ({ errorType }: { errorType: DeliveryFormField }) => {
    setFieldError({ error: errorType, value: true });
    biLogger.addressInformationContinueValidationError({
      comment: selectedAddressOption?.comment || '',
      dispatchTime,
      dispatchTimeOption: dispatchTime ? 'later' : 'asap',
      dispatchType,
      errorReason: errorType as string,
    });
  };

  const validateAddressInputAndRunSideEffects = (addressToValidate: Address | undefined = selectedAddressOption) => {
    const validateAddressReason = validateAddress({
      address: addressToValidate,
      restaurant,
      dispatchTime,
      totalOrderPrice,
    });

    if (validateAddressReason?.type === 'unavailable') {
      setFieldErrorAndBi({ errorType: 'timingOption' });
    }

    setAddressInputError({ validateAddressReason });
    setErrorVisibility({ error: 'addressInput', value: true });
    biLogger.addressInformationContinueValidationError({
      comment: addressToValidate?.comment || '',
      dispatchTime,
      dispatchTimeOption: dispatchTime ? 'later' : 'asap',
      dispatchType,
      errorReason: validateAddressReason?.type as string,
    });

    return validateAddressReason;
  };

  const submitDispatch = () => {
    setDeliveryAddressFromForm();
    onSubmit();
    toggleAllErrors({ value: false });
    saveStateToSessionStorage();
    biLogger.addressInformationContinue({
      comment: selectedAddressOption?.comment || '',
      dispatchTime,
      dispatchTimeOption: dispatchTime ? 'later' : 'asap',
      dispatchType,
    });
  };
  const handleSubmitTakeout = () => {
    const { isValid: isTakeoutValid, reason: invalidTakeoutReason } = validateTakeout(restaurant, dispatchTime);

    if (!isTakeoutValid && invalidTakeoutReason === 'unavailable') {
      setIsTakeoutUnavailable(true);
      setFieldErrorAndBi({ errorType: 'timingOption' });
    }
    if (isTakeoutValid) {
      submitDispatch();
    }
  };

  const handleSubmitDelivery = () => {
    const validateAddressReason = validateAddressInputAndRunSideEffects();
    setErrorVisibility({ error: 'addressInput', value: true });

    const isAptValid =
      Boolean(selectedAddressOption?.apt) ||
      !experiments.experiments.get('specs.restaurants.AptFieldIsRequiredInDelivery');

    toggleAllErrors({ value: true });

    if (!isAptValid) {
      setFieldErrorAndBi({ errorType: 'apt' });
    }
    if (!validateAddressReason && isAptValid) {
      submitDispatch();
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    dispatchType === 'takeout' ? handleSubmitTakeout() : handleSubmitDelivery();
  };

  const setDispatchTypeAndBi = (dispatchTypePayload: SetDispatchTypePayload) => {
    // Evid: 830 - Checkout-> Dispatch update. (addToCartFailure it's a bad name)
    biLogger.addToCartFailure({
      lastState: dispatchTypePayload.dispatchType,
    });
    setDispatchType(dispatchTypePayload);
  };

  const idSuffix = Math.random();
  const addressInformationTitleId = `restaurants.address-information.title-${idSuffix}`;

  return (
    <div
      data-hook={dataHooks.addressInformation}
      className={styles.wrapper}
      aria-labelledby={addressInformationTitleId}
      aria-describedby={describedby}
    >
      <CheckoutFlowStepTitle
        text={t('checkout_main_delivery_method')}
        done={done}
        collapsed={collapsed}
        index={index}
        onEdit={onEdit}
        editButtonDataHook={dataHooks.checkoutSummaryLineEditAddress}
        titleId={addressInformationTitleId}
      />

      {!done && !collapsed && supportedDispatchTypes.size > 1 && (
        <DispatchTypeSelector
          className={styles.selector}
          dispatchType={dispatchType}
          setDispatchType={setDispatchTypeAndBi}
        />
      )}

      {!done && !collapsed && (
        <form onSubmit={handleSubmit} data-hook={dataHooks.addressInformationForm}>
          {dispatchType === 'delivery' && (
            <AddressInformationDelivery
              restaurant={restaurant}
              dispatchTime={dispatchTime}
              totalOrderPrice={totalOrderPrice}
              onAddressInputBlur={validateAddressInputAndRunSideEffects}
              onAddressInputSelect={validateAddressInputAndRunSideEffects}
            />
          )}

          {dispatchType === 'takeout' && (
            <RestaurantTakeoutDetails
              address={restaurant.address}
              formattedAddressWithComment={formattedAddressWithComment}
            />
          )}

          <DispatchTimeSelector
            businessNotification={businessNotification}
            idealDeliveryArea={idealDeliveryArea}
            isMobile={isMobile}
            restaurant={restaurant}
            dispatchType={dispatchType}
            dispatchTime={dispatchTime}
            timingOption={dispatchTime ? 'later' : 'asap'}
            onChange={handleDispatchTimeSelectorChange}
            t={getCachedTranslationFunction(t)}
            error={timeError ? t('checkout_main_delivery_time_errormessage') : undefined}
          />

          {errorOrderItem && getErrorKey(errorOrderItem) && (
            <SectionNotification type="error" className={styles.error} data-hook={dataHooks.addressInformationError}>
              <SectionNotification.Icon icon={<Error />} />
              <SectionNotification.Text>
                <Trans
                  t={t}
                  i18nKey={getErrorKey(errorOrderItem)}
                  components={[
                    <Link data-hook={dataHooks.addressInformationErrorLink} to="/cart">
                      placeholder
                    </Link>,
                  ]}
                />
              </SectionNotification.Text>
            </SectionNotification>
          )}

          <Button
            upgrade
            fullWidth
            priority={PRIORITY.primary}
            className={styles.button}
            data-hook={dataHooks.addressInformationContinue}
            type="submit"
            disabled={!!errorOrderItem}
          >
            <Text typography="p2-m-colorless">{t('checkout_main_button_continue')}</Text>
          </Button>
        </form>
      )}

      {done && (
        <AddressInformationSummary
          address={dispatchType === 'delivery' ? address : restaurant.address}
          dispatchType={dispatchType}
          dispatchTime={dispatchTime}
          timezone={timezone}
          deliveryInfos={deliveryInfos}
          idealDeliveryArea={idealDeliveryArea}
        />
      )}
    </div>
  );
};

AddressInformation.displayName = 'AddressInformation';

export default translate()(AddressInformation);
