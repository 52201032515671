import CheckoutSubmit from './CheckoutSubmit';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { setContactlessDelivery, submitOrder } from '../../../../state/checkout/checkout.actions';
import { translate } from 'yoshi-flow-editor-runtime';
import { getOrderPolicy } from '../../../../core/logic/policyLogic';
import { openModal } from '../../../../state/session/session.actions';

function mapStateToProps(state: AppState) {
  return {
    orderPolicy: getOrderPolicy(state.session.restaurant),
    paymentMethod: state.checkout.pendingCashierPayment.paymentMethod,
    dispatchType: state.checkout.dispatch.type,
    isContactlessDeliveryChecked: state.checkout.isContactlessDeliveryChecked,
    isLoading: state.checkout.isLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ submitOrder, openModal, setContactlessDelivery }, dispatch);
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(CheckoutSubmit));
