import React from 'react';
import styles from './StickyCartButton.scss';
import CartButton from '../CartButton';

const StickyCartButton: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <CartButton />
    </div>
  );
};

StickyCartButton.displayName = 'StickyCartButton';

export default StickyCartButton;
