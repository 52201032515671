import React from 'react';
import styles from './PolicyView.scss';
import { Checkbox } from 'wix-ui-tpa/Checkbox';
import Text from '../../core-components/Text';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import dataHooks from '../../data-hooks';
import { OpenModalPayload } from '../../../../state/session/session.actions.types';
import { Modals } from '../../../../core/constants';
import { PolicyValue } from '@wix/restaurants-client-logic';
import { useBi } from 'yoshi-flow-editor-runtime';

export interface PolicyViewProps {
  className?: string;
  hasCheckbox?: boolean;
  checkedByDefault?: boolean;
  termsAndConditions?: PolicyValue;
  privacyPolicy?: PolicyValue;
  onChange: Function;
  openModal: (payload: OpenModalPayload) => void;
  showError?: boolean;
}

function handleClick(
  e: React.UIEvent<HTMLLabelElement>,
  openModal: (payload: OpenModalPayload) => void,
  modal: Modals,
  policyValue?: PolicyValue,
) {
  e.stopPropagation();

  if (policyValue && policyValue.type === 'URL') {
    window.open(policyValue.value, '_blank');
  } else if (policyValue && policyValue.type === 'TEXT') {
    openModal({ modal });
  }
}

function getText(hasCheckbox?: boolean, isSecondOption?: boolean) {
  return isSecondOption ? ' and ' : hasCheckbox ? 'I agree to the ' : 'By placing your order you agree to our ';
}

const PolicyView: React.FC<PolicyViewProps> = ({
  className,
  hasCheckbox,
  checkedByDefault,
  termsAndConditions,
  privacyPolicy,
  onChange,
  openModal,
  showError,
}) => {
  const [checked, setChecked] = React.useState(checkedByDefault);
  const biLogger = useBi();

  const handleTCClick = React.useCallback(
    (e) => {
      biLogger.checkoutReviewPolicies({ type: 'terms-and-conditions' });
      handleClick(e, openModal, Modals.TERMS_AND_CONDITIONS, termsAndConditions);
    },
    [termsAndConditions, openModal, biLogger],
  );

  const handlePPClick = React.useCallback(
    (e) => {
      biLogger.checkoutReviewPolicies({ type: 'privacy-policy' });
      handleClick(e, openModal, Modals.PRIVACY_POLICY, privacyPolicy);
    },
    [privacyPolicy, openModal, biLogger],
  );

  const content = (
    <React.Fragment>
      {termsAndConditions && (
        <React.Fragment>
          <Text typography="p2-m">{getText(hasCheckbox)}</Text>
          <TextButton
            priority={TEXT_BUTTON_PRIORITY.primary}
            onClick={handleTCClick}
            data-hook={dataHooks.policyViewTermsAndConditionsButton}
          >
            Terms & Conditions
          </TextButton>
        </React.Fragment>
      )}

      {privacyPolicy && (
        <React.Fragment>
          <Text typography="p2-m">{getText(hasCheckbox, Boolean(termsAndConditions))}</Text>
          <TextButton
            priority={TEXT_BUTTON_PRIORITY.primary}
            onClick={handlePPClick}
            data-hook={dataHooks.policyViewPrivacyPolicyButton}
          >
            Privacy Policy
          </TextButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  return (
    <div className={`${styles.wrapper} ${className}`} data-hook={dataHooks.policyView}>
      {hasCheckbox ? (
        <div className={styles.checkboxWrapper} data-hook={dataHooks.policyViewCheckboxWrapper}>
          <Checkbox
            onChange={() => {
              const newValue = !checked;
              biLogger.checkoutReviewCheckbox({ lastState: String(newValue) });
              setChecked(newValue);
              onChange && onChange();
            }}
            checked={checked}
            data-hook={dataHooks.policyViewCheckbox}
            label={content}
            error={!checked && showError}
          />
          {!checked && showError && (
            <Text
              typography="p2-m"
              className={styles.mustCheck}
              data-hook={dataHooks.checkoutReviewPolicyMustCheckText}
            >
              Please confirm you have read and agree to the terms.
            </Text>
          )}
        </div>
      ) : (
        content
      )}
    </div>
  );
};

PolicyView.displayName = 'PolicyView';

export default PolicyView;
