import React from 'react';
import { Dispatch, Store } from 'redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory, createMemoryHistory, History } from 'history';
import moment from 'moment-timezone';
import 'moment/locale/he';
import { createPreserveQueryHistory } from '../../../core/routing';
import { AppState } from '../../../state/createStore';
import { setupStore } from '../../../state/createWidgetStore';
// import VersionIndicator from '../components/VersionIndicator';
import Main from '../components/Main';
import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import HostStyleContext from '../components/HostStyleContext';

interface WidgetWrapperProps {
  appName: string;
  mobile: boolean;
  language: string;
  appState: AppState;
  dispatch: Dispatch;
  basePath: string;
  shouldNotRenderMain: boolean;
  host: IHostProps;
}

export default class extends React.Component<WidgetWrapperProps> {
  store: Store;
  updateStore: (appState: AppState) => void;
  history: History;

  constructor(props: WidgetWrapperProps) {
    super(props);

    const { store, updateStore } = setupStore(props.appState, props.dispatch);
    this.store = store;
    this.updateStore = updateStore;

    if (typeof location !== 'undefined') {
      this.history = createPreserveQueryHistory(createBrowserHistory)({
        basename: props.basePath.replace(location.protocol + '//' + location.hostname, ''),
      });
    } else {
      this.history = createPreserveQueryHistory(createMemoryHistory)();
    }

    moment.locale(props.language);
  }

  componentDidMount() {
    performance?.mark && performance.mark('restaurant-olo-client-loaded');
  }

  shouldComponentUpdate(prevProps: WidgetWrapperProps) {
    return prevProps.appState !== this.props.appState;
  }

  componentDidUpdate() {
    this.updateStore(this.props.appState);
  }

  render() {
    return (
      <React.Fragment>
        {/* <VersionIndicator /> */}
        <HostStyleContext.Provider value={this.props.host.style}>
          <Provider store={this.store}>
            <Router history={this.history}>{!this.props.shouldNotRenderMain && <Main />}</Router>
          </Provider>
        </HostStyleContext.Provider>
      </React.Fragment>
    );
  }
}
