import OrderFailureModal from './OrderFailureModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setCheckoutStep, setErrorType } from '../../../../state/checkout/checkout.actions';
import { translate } from 'yoshi-flow-editor-runtime';
import { isCoupon, isSupportsFutureOrder } from '@wix/restaurants-client-logic';

function mapStateToProps(state: AppState, ...ownProps: any) {
  const { errorChargeId } = state.checkout;
  const chargeInMenu = errorChargeId && state.session.menu.chargesV2?.find((el) => el.id === errorChargeId);
  const isCouponError = chargeInMenu && isCoupon(chargeInMenu);
  const hasFutureOrders = isSupportsFutureOrder(state.session.restaurant);

  return {
    restaurantPhone: state.session.restaurant.contact.phone,
    errorType: state.checkout.errorType,
    errorCode: state.checkout.errorCode,
    isCouponError,
    hasFutureOrders,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetErrors: () => setErrorType({ errorType: undefined, errorCode: undefined, errorChargeId: undefined }),
      setCheckoutStep,
    },
    dispatch,
  );
}
export default translate()(connect(mapStateToProps, mapDispatchToProps)(OrderFailureModal));
