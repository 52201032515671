import React, { useState } from 'react';
import ModalLayoutSelection from '../ModalLayoutSelection';
import ModalLayoutBasicFooter from '../ModalLayoutBasicFooter';
import DispatchTypeSelector from '../DispatchTypeSelector';
import DispatchTimeSelector from '../DispatchTimeSelector';
import {
  SetDeliveryAddressPayload,
  SetDispatchTimePayload,
  SetDispatchTypePayload,
} from '../../../../state/checkout/checkout.actions.types';
import styles from './DispatchSettings.scss';
import RestaurantTakeoutDetails from '../AddressInformation/RestaurantTakeoutDetails';
import { useBi, translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import dataHooks from '../../data-hooks';
import {
  Address,
  DispatchInfo,
  DispatchType,
  getCachedTranslationFunction,
  Restaurant,
} from '@wix/restaurants-client-logic';
import { BusinessNotificationDetails } from '../../../../state/selectors/businessNotificationSelector';

export type TimingOption = 'asap' | 'later';

export interface DispatchSettingsProps {
  onRequestClose: () => void;
  initialDispatchType: DispatchType;
  initialAddress?: Address;
  initialTimingOption?: TimingOption;
  restaurant: Restaurant;
  supportedDispatchTypes: Set<DispatchType>;
  setDispatchType: (dispatchType: SetDispatchTypePayload) => void;
  setDispatchTime: (dispatchType: SetDispatchTimePayload) => void;
  setDeliveryAddress: (address: SetDeliveryAddressPayload) => void;
  initialDispatchTime?: number;
  formattedAddressWithComment: string;
  isMobile: boolean;
  t: TranslationFunction;
  idealDeliveryArea?: DispatchInfo;
  businessNotification?: BusinessNotificationDetails;
}

const DispatchSettings: React.FC<DispatchSettingsProps> = ({
  onRequestClose,
  initialDispatchType,
  initialAddress,
  initialTimingOption,
  restaurant,
  supportedDispatchTypes,
  setDispatchType,
  setDispatchTime,
  setDeliveryAddress,
  initialDispatchTime,
  formattedAddressWithComment,
  t,
  isMobile,
  idealDeliveryArea,
  businessNotification,
}) => {
  const biLogger = useBi();
  const [dispatchType, setType] = useState(initialDispatchType);
  const [timingOption, setTimingOption] = useState(initialTimingOption);
  const [selectedDateTime, setSelectedDateTime] = useState(initialDispatchTime || 0);

  const handleOkClick = () => {
    biLogger.dispatchSettingsUpdate({ dispatchTime: selectedDateTime, dispatchTimeOption: timingOption, dispatchType });
    setDispatchType({ dispatchType });

    if (dispatchType === 'delivery' && initialAddress) {
      setDeliveryAddress({ address: initialAddress });
    }

    if (timingOption === 'later') {
      setDispatchTime({ timestamp: selectedDateTime });
    } else {
      setDispatchTime({ timestamp: undefined });
    }

    onRequestClose();
  };

  const footer = (
    <ModalLayoutBasicFooter
      isMobile={isMobile}
      onOkClick={handleOkClick}
      onCancelClick={onRequestClose}
      fullWidthMobile
    />
  );

  return (
    <ModalLayoutSelection
      header={t('order_settings_modal_title')}
      footer={footer}
      onCloseClick={onRequestClose}
      data-hook="dispatch-settings-modal"
    >
      <div className={styles.wrapper} data-hook={dataHooks.dispatchSettingWrapper}>
        {supportedDispatchTypes.size > 1 && (
          <DispatchTypeSelector
            className={styles.selector}
            dispatchType={dispatchType}
            setDispatchType={(payload: SetDispatchTypePayload) => setType(payload.dispatchType)}
          />
        )}
        {dispatchType === 'takeout' && (
          <RestaurantTakeoutDetails
            address={restaurant.address}
            formattedAddressWithComment={formattedAddressWithComment}
          />
        )}
        <DispatchTimeSelector
          businessNotification={businessNotification}
          idealDeliveryArea={idealDeliveryArea}
          isMobile={isMobile}
          restaurant={restaurant}
          dispatchType={dispatchType}
          timingOption={timingOption}
          dispatchTime={selectedDateTime}
          onChange={({
            timingOption: newTimingOption,
            selectedDateTime: newSelectedDateTime,
          }: {
            timingOption: TimingOption;
            selectedDateTime?: number;
          }) => {
            setSelectedDateTime(newSelectedDateTime || 0);
            setTimingOption(newTimingOption);
          }}
          t={getCachedTranslationFunction(t)}
        />
      </div>
    </ModalLayoutSelection>
  );
};

DispatchSettings.displayName = 'DispatchSettings';
export default translate()(DispatchSettings);
