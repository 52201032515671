import { DisplayableOrderItem } from '@wix/restaurants-client-logic';

export function getErrorKey(orderItem: DisplayableOrderItem) {
  if (orderItem.isDishDeleted) {
    return 'cart_issues_error_hidden';
  }

  const error = orderItem.errors[0];

  switch (error?.type) {
    case 'order_delivery_type':
      return 'cart_issues_error_delivery_method_unavailable';
    case 'order_delivery_time':
      return error.reason === 'soldout' ? 'cart_issues_error_out_of_stock' : 'cart_issues_error_delivery_time_change';
    case 'order_platform':
      return 'cart_issues_error_unavailable_platform';
    default:
      return undefined;
  }
}
