import React from 'react';
import Text from '../../core-components/Text';
import styles from './AddressInformation.scss';
import dataHooks from '../../data-hooks';
import { TranslationFunction } from 'i18next';
import { translate } from 'yoshi-flow-editor-runtime';
import { Address } from '@wix/restaurants-client-logic';

interface AddressInformationTakeoutProps {
  formattedAddressWithComment: string;
  address: Address;
  t: TranslationFunction;
}

const RestaurantTakeoutDetails: React.FC<AddressInformationTakeoutProps> = ({ formattedAddressWithComment, t }) => {
  return (
    <React.Fragment>
      <Text typography="p2-s" className={styles.restaurantAddressLabel}>
        {t('order_settings_modal_pickup_label')}
      </Text>
      <Text
        typography="p2-m"
        data-hook={dataHooks.addressInformationTakeoutAddress}
        className={styles.restaurantAddress}
      >
        {formattedAddressWithComment}
      </Text>
    </React.Fragment>
  );
};

RestaurantTakeoutDetails.displayName = 'RestaurantTakeoutDetails';

export default translate()(RestaurantTakeoutDetails);
