import React from 'react';
import { translate, useBi } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import moment from 'moment-timezone';
import dataHooks from '../../data-hooks';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import styles from './DispatchSummary.scss';
import { RouteUrls } from '../../../../core/constants';
import Text from '../../core-components/Text';
import { Address, DispatchType, DispatchInfo } from '@wix/restaurants-client-logic';
import { useHistory } from 'react-router-dom';
import { getAsapText } from '../DispatchTimeSelector/DispatchTimeSelector.helper';

export interface DispatchSummaryProps {
  dispatchType: DispatchType;
  dispatchTime?: number;
  timezone: string;
  address?: Address;
  deliveryInfos: DispatchInfo[];
  t: TranslationFunction;
  idealDeliveryArea?: DispatchInfo;
  disabled?: boolean;
}

function getText(
  t: TranslationFunction,
  dispatchType: DispatchType,
  timezone: string,
  deliveryInfos: DispatchInfo[],
  dispatchTime?: number,
  idealDeliveryArea?: DispatchInfo,
) {
  const timing = dispatchTime
    ? moment(dispatchTime).tz(timezone).format('MMM D, h:mm A')
    : getAsapText({ deliveryInfos, timezone, dispatchType, t, idealDeliveryArea });

  return `${dispatchType === 'delivery' ? t('checkout_button_delivery') : t('checkout_button_pickup')}, ${timing}`;
}

const DispatchSummary: React.FC<DispatchSummaryProps> = ({
  dispatchType,
  dispatchTime,
  timezone,
  deliveryInfos,
  t,
  idealDeliveryArea,
  disabled,
}) => {
  const biLogger = useBi();
  const history = useHistory();

  const handleClick = (e: React.SyntheticEvent) => {
    if (!disabled) {
      e.stopPropagation();
      biLogger.openDispatchSettings({});
      history.push(RouteUrls.DISPATCH_SETTINGS_MODAL);
    }
  };

  return (
    <div
      onClick={handleClick}
      data-hook={dataHooks.dispatchSummaryWrapper}
      className={`${styles.wrapper} ${disabled && styles.disabled}`}
    >
      <Text typography="p2-m" data-hook={dataHooks.dispatchSummaryText} className={styles.dispatchSummaryText}>
        {getText(t, dispatchType, timezone, deliveryInfos, dispatchTime, idealDeliveryArea)}
      </Text>
      <TextButton
        priority={TEXT_BUTTON_PRIORITY.primary}
        onClick={handleClick}
        data-hook={dataHooks.dispatchSummary}
        className={styles.button}
        disabled={disabled}
      >
        <Text typography="p2-m-colorless">{t('online_ordering_delivery_time_change')}</Text>
      </TextButton>
    </div>
  );
};

DispatchSummary.displayName = 'DispatchSummary';

export default translate()(DispatchSummary);
