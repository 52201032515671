import React from 'react';
import {
  TextButton as TpaTextButton,
  TextButtonProps as TpaTextButtonProps,
  TEXT_BUTTON_PRIORITY,
} from 'wix-ui-tpa/TextButton';
import { st, classes } from './TextButton.st.css';

export interface TextButtonProps extends TpaTextButtonProps {}

const TextButton: React.FC<TextButtonProps> = (props) => {
  return <TpaTextButton {...props} className={st(classes.root, {}, props.className)} />;
};

TextButton.displayName = 'TextButton';

export { TextButton };
export { TEXT_BUTTON_PRIORITY };
