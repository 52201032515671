import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import CartButton from './CartButton';
import { Modals } from '../../../../core/constants';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';

function mapStateToProps(state: AppState, ownProps: any) {
  const { itemCount } = cartSummarySelector(state);

  return {
    ...ownProps,
    dishModalOpen: !!state.session.modals.find((modal) => modal.modal === Modals.DISH_MODAL),
    itemCount,
  };
}

export default connect(mapStateToProps)(CartButton);
