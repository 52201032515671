import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { closeModal, navigate, openModal } from '../../../../state/session/session.actions';
import { bindActionCreators, Dispatch } from 'redux';
import {
  addOrderCoupon,
  changeOrderItemCount,
  removeOrderCoupon,
  removeOrderItem,
  setOrderComment,
} from '../../../../state/cart/cart.actions';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { hasCouponsSelector } from '../../../../state/selectors/hasCouponsSelector';
import Cart from './Cart';
import { translate } from 'yoshi-flow-editor-runtime';

function mapStateToProps(state: AppState, ownProps: any) {
  const {
    displayableDiscounts,
    displayablePriceComponents,
    displayableOrderItems,
    minOrderPrice,
  } = cartSummarySelector(state);

  return {
    comment: state.cart.comment,
    displayableDiscounts,
    displayablePriceComponents,
    displayableOrderItems,
    couponCode: (state.cart.coupon?.type === 'success' && state.cart.coupon.code) || undefined,
    couponError: (state.cart.coupon?.type === 'failure' && state.cart.coupon.message) || undefined,
    hasCoupons: hasCouponsSelector(state),
    minOrderPrice,
    dispatchType: state.checkout.dispatch.type,
    isMobile: state.platformParams.isMobile,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      navigate,
      closeModal,
      setOrderComment,
      addOrderCoupon,
      removeOrderCoupon,
      removeOrderItem,
      changeOrderItemCount,
      openModal,
    },
    dispatch,
  );
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(Cart));
