import { createSelector } from 'reselect';
import { AppState } from '../createStore';
import { getSupportedDispatchTypes } from '@wix/restaurants-client-logic';
import { getDeliveryDisplayableMenuSelector } from './menuViewSelector';

export const supportedDispatchTypesSelector = createSelector(
  [
    (state: AppState) => state.session.restaurant.deliveryInfos,
    (state: AppState) => getDeliveryDisplayableMenuSelector(state),
  ],
  (deliveryInfos, deliveryDisplayableMenu) => getSupportedDispatchTypes({ deliveryInfos, deliveryDisplayableMenu }),
);
