import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import ThankYou from './ThankYou';
import { bindActionCreators, Dispatch } from 'redux';
import { initCheckout } from '../../../../state/checkout/checkout.actions';
import { initAddressForm } from '../../../../state/addressInformationForm/addressForm.actions';

function mapStateToProps(state: AppState) {
  return {
    order: state.checkout.orderResponse!,
    locale: state.session.restaurant.locale,
    currency: state.session.restaurant.currency,
    timezone: state.session.restaurant.timezone,
    restaurant: state.session.restaurant,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      initCheckout,
      initAddressForm,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
