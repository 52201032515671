import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TranslationFunction } from 'i18next';
import CartIcon from '../CartIcon';
import styles from './CartButton.scss';
import Text from '../../core-components/Text';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { useBi, translate } from 'yoshi-flow-editor-runtime';
import Button from '../Button';
import dataHooks from '../../data-hooks';

function noop() {}
const isIphone = typeof navigator !== 'undefined' && navigator.platform === 'iPhone';

export interface CartButtonProps {
  itemCount: number;
  fullWidth?: boolean;
  dishModalOpen?: boolean;
  t: TranslationFunction;
}

const CartButton: React.FC<CartButtonProps> = ({ itemCount, fullWidth, dishModalOpen, t }) => {
  const [pointerEvents, setPointerEvents] = React.useState(!isIphone);
  const history = useHistory();
  const hook = dataHooks.openCartButton;
  const biLogger = useBi();

  useEffect(() => {
    if (isIphone) {
      if (dishModalOpen) {
        setPointerEvents(false);
      } else {
        setTimeout(() => {
          setPointerEvents(true);
        }, 250);
      }
    }
  }, [dishModalOpen]);

  const onViewCartBtnClick = () => {
    biLogger.viewCart({ totalItemsCount: itemCount });
    history.push('/cart');
  };

  const button = (
    <div className={styles.iconWithCount}>
      <CartIcon className={styles.cartIcon} />
      <Text typography="p2-s-colorless" className={styles.textEmpty} data-hook={dataHooks.cartButtonItemCount}>
        {itemCount}
      </Text>
    </div>
  );

  const empty = (
    <button
      aria-label={t('cart_viewcart_cta')}
      className={styles.wrapperEmpty}
      data-hook={hook}
      onClick={() => history.push('/cart')}
    >
      {button}
    </button>
  );

  const full = (
    <Button
      upgrade
      priority={PRIORITY.primary}
      suffixIcon={button}
      className={`${styles.button} ${!pointerEvents ? styles.noPointer : ''}`}
      data-hook={hook}
      onClick={onViewCartBtnClick}
      fullWidth={fullWidth}
      onBlur={noop}
      onFocus={noop}
    >
      <Text typography="p2-m-colorless">{t('online_ordering_cta_view_cart')}</Text>
    </Button>
  );

  return itemCount === 0 ? empty : full;
};

CartButton.displayName = 'CartButton';

export default translate()(CartButton);
