import React, { useEffect } from 'react';
import styles from './NavigationHeader.scss';
import PseudoLinks from '../PseudoLinks';
import CartButton from '../CartButton';
import SectionTabs from '../SectionTabs';
import { useBi, useExperiments } from 'yoshi-flow-editor-runtime';
import dataHooks from '../../data-hooks';
import { calculateMenuScrollOffset, scrollToMenuOrSection } from '../Scroller/scrollingUtils';
import FloatingDropdown from '../FloatingDropdown';
import { useHistory } from 'react-router-dom';
import { DisplayableMenu } from '@wix/restaurants-client-logic';

export interface NavigationHeaderEntity {
  id: string;
  title?: string;
  slug: string;
}

export interface NavigationHeaderProps {
  menus: NavigationHeaderEntity[];
  sections: Record<string, NavigationHeaderEntity[]>;
  initialMenuId?: string;
  initialSectionId?: string;
  showButtonPlaceholder?: boolean;
  isRTL?: boolean;
  displayableMenu?: DisplayableMenu;
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  menus,
  sections,
  initialMenuId,
  initialSectionId,
  showButtonPlaceholder,
  displayableMenu,
  isRTL,
}) => {
  const [selectedMenuId, setMenuId] = React.useState(initialMenuId || menus[0]?.id);
  const [selectedSectionId, setSectionId] = React.useState(initialSectionId || sections[menus[0]?.id]?.[0]?.id);
  const biLogger = useBi();
  const history = useHistory();
  const { experiments } = useExperiments();
  const isMenuPermalinkEnabled = experiments.enabled('specs.restaurants.ooi-menu-permalink');
  const isSimpleCartButtonEnabled = experiments.enabled('specs.restaurants.olo-client-simple-cart-button');

  useEffect(() => {
    // In case displayableMenu is changed, we must reset the selection
    // state. Example use-case: Visitor has changed dispatchTime, which
    // caused the menus to re-order by availability.
    setMenuId(initialMenuId || menus[0]?.id);
    setSectionId(initialSectionId || sections[menus[0]?.id]?.[0]?.id);
  }, [displayableMenu, setMenuId, setSectionId, sections, menus, initialMenuId, initialSectionId]);

  if (menus.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper} data-hook={dataHooks.navigationHeader}>
      <div className={styles.dropdown}>
        <FloatingDropdown
          label=""
          className={styles.floatingDropdown}
          options={menus.map((e) => ({ id: e.id, slug: e.slug, value: e.title || '', isSelectable: true }))}
          value={selectedMenuId}
          data-hook={dataHooks.navigationHeaderDropdown}
          onChange={(selectedOption) => {
            if (selectedOption.id) {
              biLogger.navigateToMenu({ menuId: selectedOption.id, menuName: selectedOption.value });

              if (isMenuPermalinkEnabled) {
                const menuSlug = (selectedOption as NavigationHeaderEntity).slug;
                const sectionSlug = sections[selectedOption.id][0]?.slug;
                const slug = sectionSlug ? `/${sectionSlug}` : `/${menuSlug}`;
                history.push(slug);
              } else {
                scrollToMenuOrSection(selectedOption.id);
              }

              setMenuId(selectedOption.id);
              setSectionId(sections[selectedOption.id][0]?.id);
            }
          }}
          displayBlock
        />
        <div className={styles.divider} />
      </div>
      <SectionTabs
        className={styles.tabs}
        dataHook={dataHooks.navigationHeaderTabs}
        sections={sections[selectedMenuId] ? sections[selectedMenuId] : sections[initialMenuId || menus[0]?.id]}
        selectedSectionId={selectedSectionId}
        isRTL={isRTL}
      />
      <div className={styles.cartButton}>{(showButtonPlaceholder || isSimpleCartButtonEnabled) && <CartButton />}</div>

      <PseudoLinks
        menus={menus}
        sections={sections[selectedMenuId] ? sections[selectedMenuId] : sections[initialMenuId || menus[0]?.id]}
        offset={calculateMenuScrollOffset()}
        onChange={(type, id) => {
          if (type === 'menu') {
            setMenuId(id);
            setSectionId(sections[id][0]?.id);
          } else if (type === 'section') {
            setSectionId(id);
          }
        }}
      />
    </div>
  );
};

NavigationHeader.displayName = 'NavigationHeader';

export default NavigationHeader;
