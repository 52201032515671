import React from 'react';
import styles from './DrawerLayout.scss';
import Text from '../../core-components/Text';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import dataHooks from '../../data-hooks';
import { withHandleIosResize, InjectedStyleProps } from '../../../../core/logic/iosResizeLogic';

export interface DrawerLayoutProps extends InjectedStyleProps {
  children?: React.ReactNode;
  header: React.ReactNode;
  footer: React.ReactNode;
  onCloseClick: () => void;
  hook: string;
  isMobile?: boolean;
}

export const DRAWER_LAYOUT_CONTENT_CONTAINER = 'lock-drawer-layout';

function lockScroll() {
  unlockScroll().then(() => {
    disableBodyScroll(document.getElementById('lock-drawer-layout') as any);
  });
}

function unlockScroll() {
  return new Promise((resolve) => {
    clearAllBodyScrollLocks();
    setTimeout(resolve, 16);
  });
}

class DrawerLayout extends React.Component<DrawerLayoutProps> {
  componentDidMount() {
    lockScroll();
  }

  componentWillUnmount() {
    unlockScroll();
  }

  render() {
    const { header, children, footer, onCloseClick, hook, headerStyle, footerStyle } = this.props;
    return (
      <div className={styles.wrapper} data-hook={hook}>
        <div className={styles.headerWrapper} style={headerStyle ? headerStyle : {}}>
          <Text typography="header-m" data-hook="cart-modal-layout-title" className={styles.header} id="modalTitle">
            {header}
          </Text>
          <button
            data-hook={dataHooks.closeCartButton}
            onClick={() => onCloseClick()}
            className={styles.closeIcon}
            aria-label={'Close cart'}
          >
            <Close />
          </button>
        </div>
        <div className={styles.contentWrapper} id={DRAWER_LAYOUT_CONTENT_CONTAINER}>
          <div className={styles.content}>{children}</div>
        </div>
        <div className={styles.footer} style={footerStyle ? footerStyle : {}}>
          {footer}
        </div>
      </div>
    );
  }
}
export default withHandleIosResize(DrawerLayout);
