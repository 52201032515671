import React from 'react';
import Quantity from '../Quantity';
import { PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import styles from './DishModalFooter.scss';
import dataHooks from '../../data-hooks';
import Button from '../Button';
import Text from '../../core-components/Text';
import { DisplayableOrderItem } from '@wix/restaurants-client-logic';
import { translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';

export const ADD_TO_MY_ORDER_TEXT = 'Add to my order';

export interface DishModalFooterPropagatedProps {
  isEditingItemFromTheCart: boolean;
  priceDifference?: string;
}
export interface DishModalFooterProps {
  onSubmitButtonClick: () => void;
  displayableOrderItem: DisplayableOrderItem;
  onQuantityChange: (quantity: number) => void;
  t: TranslationFunction;
}

const DishModalFooter: React.FC<DishModalFooterProps & DishModalFooterPropagatedProps> = ({
  onSubmitButtonClick,
  displayableOrderItem,
  onQuantityChange,
  isEditingItemFromTheCart,
  priceDifference,
  t,
}) => {
  const getAddToOrderButtonText = () => {
    return isEditingItemFromTheCart ? getEditDishText() : getAddNewDishText();
  };
  const getEditDishText = () => {
    const priceStr = `${displayableOrderItem.totalPrice} (${
      priceDifference!.includes('-') ? '' : '+'
    }${priceDifference})`;
    return t('checkout_main_updateorder_cta', { price: priceStr });
  };
  const getAddNewDishText = () => {
    return t('online_ordering_menuitem_addtoorder_button', { price: displayableOrderItem.totalPrice });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.quantity}>
        <Quantity
          data-hook={dataHooks.dishModalCounter}
          value={displayableOrderItem.quantity}
          onChange={(val) => {
            Number(val) > 0 && Number(val) < 1000 && onQuantityChange(Number(val));
          }}
          aria-label="Amount Selector"
          inputAriaLabel="Amount"
          incrementAriaLabel="Increase"
          decrementAriaLabel="Decrease"
          step={1}
          min={1}
          disabled={displayableOrderItem.submitDisabled}
        />
      </div>
      <div className={styles.spacer} />
      <Button
        className={styles.button}
        upgrade
        fullWidth
        disabled={displayableOrderItem.submitDisabled}
        data-hook={dataHooks.dishModalAddToCart}
        onClick={() => {
          onSubmitButtonClick();
        }}
        priority={PRIORITY.primary}
        size={SIZE.large}
      >
        <Text typography="p2-m-colorless">{getAddToOrderButtonText()}</Text>
      </Button>
    </div>
  );
};

DishModalFooter.displayName = 'DishModalFooter';

export default translate()(DishModalFooter);
