import DispatchSettings, { TimingOption } from './DispatchSettings';
import { translate } from 'yoshi-flow-editor-runtime';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { bindActionCreators, Dispatch } from 'redux';
import { setDeliveryAddress, setDispatchTime, setDispatchType } from '../../../../state/checkout/checkout.actions';
import { supportedDispatchTypesSelector } from '../../../../state/selectors/supportedDispatchTypesSelector';
import { formattedAddressWithCommentSelector } from '../../../../state/selectors/formattedAddressWithCommentSelector';
import { deliveryAreaSelectorByDispatchAddress } from '../../../../state/selectors/deliveryAreaSelector';
import { businessNotificationSelector } from '../../../../state/selectors/businessNotificationSelector';

function mapStateToProps(state: AppState) {
  const idealDeliveryArea = deliveryAreaSelectorByDispatchAddress(state);
  const businessNotification = businessNotificationSelector(state);

  return {
    initialTimingOption: (state.checkout.dispatch.time ? 'later' : 'asap') as TimingOption,
    initialDispatchTime: state.checkout.dispatch.time,
    initialDispatchType: state.checkout.dispatch.type,
    initialAddress: state.checkout.dispatch.type === 'delivery' ? state.checkout.dispatch.address : undefined,
    availability: state.session.restaurant.openTimes,
    restaurant: state.session.restaurant,
    supportedDispatchTypes: supportedDispatchTypesSelector(state),
    formattedAddressWithComment: formattedAddressWithCommentSelector(state),
    isMobile: state.platformParams.isMobile,
    idealDeliveryArea: idealDeliveryArea?.dispatchInfo,
    businessNotification,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setDispatchType,
      setDispatchTime,
      setDeliveryAddress,
    },
    dispatch,
  );
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(DispatchSettings));
