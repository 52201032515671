import React from 'react';
import Text from '../../core-components/Text';
import styles from './ContactInformation.scss';
import TextField from '../TextField';
import { SetContactFieldPayload } from '../../../../state/checkout/checkout.actions.types';
import { Contact } from '@wix/restaurants-client-logic';

export interface ContactInformationInputProps {
  contact: Contact;
  field: keyof Contact;
  onChange: (payload: SetContactFieldPayload) => void;
  label: string;
  errorMessage?: string;
  'data-hook': string;
  type?: string;
  autoFocus?: boolean;
}

const ContactInformationInput: React.FC<ContactInformationInputProps> = ({
  contact,
  field,
  onChange,
  label,
  errorMessage,
  type = 'text',
  autoFocus,
  ...rest
}) => {
  return (
    <label>
      <Text typography="p2-s" className={styles.label}>
        {label}
      </Text>
      <TextField
        maxLength={240}
        value={contact[field]}
        onChange={(e) => onChange({ field, value: e.target.value })}
        data-hook={rest['data-hook']}
        error={Boolean(errorMessage)}
        errorMessage={errorMessage}
        type={type}
        autoFocus={autoFocus}
      />
    </label>
  );
};

ContactInformationInput.displayName = 'ContactInformationInput';

export default ContactInformationInput;
