import { VariationLimitation } from '@wix/restaurants-client-logic';

export function limitationToString(limitation: VariationLimitation, min?: number, max?: number): string {
  // This function should be replace with translated strings.
  switch (limitation) {
    case 'AT_LEAST_MIN':
      return `Required. Please select at least ${min} options.`;
    case 'AT_LEAST_ONE':
      return `Required. Please select at least one option.`;
    case 'EXACTLY':
      return `Required. Please select ${min} options.`;
    case 'RANGE':
      return `Required. Please select ${min}-${max} options.`;
    case 'UP_TO_ONE':
      return `Please select up to one option.`;
    case 'UP_TO_MAX':
      return `Please select up to ${max} options.`;
    case 'EXACTLY_ONE':
      return `Please select one option.`;
    default:
      return '';
  }
}
