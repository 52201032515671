import React from 'react';
import NavigationHeader from '../NavigationHeader';
import Sticky from '../Sticky';
import styles from './StickyNavigationHeader.scss';

export interface StickyNavigationHeaderProps {}

const StickyNavigationHeader: React.FC<StickyNavigationHeaderProps> = () => {
  const [showButtonPlaceholder, toggleShowButtonPlaceholder] = React.useState(false);

  return (
    <Sticky className={styles.wrapper} onChange={(isSticky) => toggleShowButtonPlaceholder(isSticky)}>
      <NavigationHeader showButtonPlaceholder={showButtonPlaceholder} />
    </Sticky>
  );
};

StickyNavigationHeader.displayName = 'StickyNavigationHeader';

export default StickyNavigationHeader;
