import React from 'react';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import styles from './CheckoutReview.scss';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import Scroller from '../Scroller';
import { translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';

export interface CheckoutReviewProps {
  done?: boolean;
  collapsed?: boolean;
  index?: string;
  t: TranslationFunction;
  describedby?: string;
}

const CheckoutReview: React.FC<CheckoutReviewProps> = ({ index = '1', collapsed, done, t, describedby }) => {
  const titleId = `${dataHooks.checkoutReview}-titleId`;

  return (
    <Scroller name="checkout-review" condition={!done && !collapsed}>
      <div
        data-hook={dataHooks.checkoutReview}
        className={styles.wrapper}
        aria-describedby={describedby}
        aria-labelledby={titleId}
      >
        <CheckoutFlowStepTitle
          text={t('checkout_main_orderreview_title')}
          index={index}
          collapsed={collapsed}
          done={done}
          titleId={titleId}
        />
        {!collapsed && (
          <Text typography="p2-m" className={styles.description}>
            {t('checkout_main_orderreview_text')}
          </Text>
        )}
      </div>
    </Scroller>
  );
};

CheckoutReview.displayName = 'CheckoutReview';

export default translate()(CheckoutReview);
