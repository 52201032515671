import React from 'react';
import { SetDispatchTypePayload } from '../../../../state/checkout/checkout.actions.types';
import styles from './DispatchTypeSelector.scss';
import dataHooks from '../../data-hooks';
import { translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import Text from '../../core-components/Text';
import { DispatchType } from '@wix/restaurants-client-logic';

export interface DispatchTypeSelectorProps {
  className?: string;
  dispatchType: DispatchType;
  setDispatchType: (dispatchType: SetDispatchTypePayload) => void;
  t: TranslationFunction;
}

const DispatchTypeSelector: React.FC<DispatchTypeSelectorProps> = ({ className, dispatchType, setDispatchType, t }) => {
  return (
    <div data-hook="dispatch-type-selector" className={`${styles.wrapper} ${className}`}>
      <button
        className={dispatchType === 'delivery' ? styles.selected : ''}
        onClick={() => setDispatchType({ dispatchType: 'delivery' })}
        data-hook={dataHooks.dispatchSummarySelectorDelivery}
        autoFocus
      >
        <Text typography="p2-m">{t('checkout_main_delivery_button')}</Text>
      </button>
      <button
        className={dispatchType !== 'delivery' ? styles.selected : ''}
        onClick={() => setDispatchType({ dispatchType: 'takeout' })}
        data-hook={dataHooks.dispatchSummarySelectorTakeout}
      >
        <Text typography="p2-m">{t('checkout_main_pickup_button')}</Text>
      </button>
    </div>
  );
};

DispatchTypeSelector.displayName = 'DispatchTypeSelector';

export default translate()(DispatchTypeSelector);
